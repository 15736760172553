

























































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
    apiStorehouseAdd,
    apiStorehouseDetail,
    apiStorehouseEdit,
    apiStorehouseList
} from '@/api/goods'
import { apiAddGiftcard } from '@/api/marketing/gift_card'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddCategory extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false

    categoryList = []
    lock = false
    form: any = {
        name: '',
        face_value: 0,
        number: 1
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入礼品卡名称',
                trigger: ['blur', 'change']
            }
        ],
        face_value: [
            {
                required: true,
                message: '请输入面值',
                trigger: ['blur', 'change']
            }
        ],
        number: [
            {
                required: true,
                message: '请输入数量',
                trigger: ['blur', 'change']
            }
        ]
    }

    handleSave() {
        if (this.lock) {
            return
        }
        this.lock = true
        this.loading = true
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                const api = this.id
                    ? apiStorehouseEdit(this.form)
                    : apiAddGiftcard(this.form)
                api.then(() => {
                    this.loading = false
                    this.lock = false
                    this.$router.go(-1)
                })
            } else {
                this.lock = false
                return false
            }
        })
    }

    getCategoryDetail() {
        this.loading = true
        apiStorehouseDetail(this.id).then((res: any) => {
            this.form = res
            this.loading = false
        })
    }

    getCategoryList() {
        apiStorehouseList({}).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
            this.categoryList = res?.lists
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getCategoryDetail()
        this.getCategoryList()
    }
}
